import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { API } from '@ui-resources-angular';
import twitterText from 'twitter-text';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../popup/popup.service';

@Injectable()
export class TranslationService {
  private renderer: Renderer2;

  constructor(
    private api: API,
    private translate: TranslateService,
    private popup: PopupService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  escapeEntities(str) {
    const escapedMentions = twitterText
      .autoLinkUsernamesOrLists(str)
      .replace(/@<a /g, '<a ');
    return twitterText
      .autoLinkHashtags(escapedMentions)
      .replace(/#<a /g, '<a ');
  }

  unescapeEntities(str: string) {
    const span: HTMLElement = this.renderer.createElement('span');
    span.innerHTML = str;
    const anchorTags = span.getElementsByTagName('a');
    Array.from(anchorTags).forEach((aElm) => {
      if (aElm.classList.contains('username')) {
        aElm.innerHTML = `@${aElm.textContent}`;
        aElm.setAttribute('data-mention-value', '');
      } else if (aElm.classList.contains('hashtag')) {
        aElm.innerHTML =
          '#' +
          aElm.setAttribute(
            'href',
            `https://x.com/search?q=%23${aElm.textContent}`
          );
      }
    });
    return span.textContent;
  }

  translateText({
    text,
    target = this.translate.currentLang
  }): Promise<{ detectedSourceLanguage: string; translatedText: string }> {
    return this.api
      .post('translation/text', {
        q: this.escapeEntities(text),
        target
      })
      .then(
        ({
          data: {
            data: { translations }
          }
        }: any) => {
          if (translations.length === 0) {
            this.popup.alert({
              title: this.translate.instant('TRANSLATION_FAILED'),
              message: this.translate.instant(
                'SORRY_BUT_WE_COULDNT_AUTOMATICALLY_TRANSLATE_THIS_MESSAGE'
              )
            });
            return Promise.reject('No translations detected');
          } else {
            return translations.map((translation) => {
              translation.translatedText = this.unescapeEntities(
                translation.translatedText
              );
              return translation;
            });
          }
        }
      );
  }
}
