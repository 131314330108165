import { utils, Record } from 'js-data';
import { SocialNetwork, socialNetworkSettings } from '../../constants';
import { api } from '../../core/services/api';
import { User, UserModel } from '../../user/services/userModel';
import { services } from '../../common';
import {
  ColleaguesService,
  Colleague
} from '../../../../../apps/angular/common/services/api';
import { appInjector } from '../../../../../apps/angular/app-injector';
import { Model } from '../../model';
import { WorkflowModel } from './workflowModel';
import { VanityDomain } from './vanityDomainModel';
import { KeyValueObject } from '../../../../../apps/angular/common/util';

export enum AccountType {
  Facebook = 'Facebook',
  FacebookAds = 'Facebook Ads',
  FacebookGroup = 'Facebook Group',
  GooglePlus = 'Google+',
  Instagram = 'Instagram',
  InstagramBusiness = 'Instagram Business',
  LinkedIn = 'LinkedIn',
  LinkedInAds = 'LinkedIn Ads',
  TwilioSMS = 'Twilio SMS',
  TwilioWhatsApp = 'Twilio WhatsApp',
  Twitter = 'Twitter',
  TwitterAds = 'Twitter Ads',
  YouTube = 'YouTube',
  TikTok = 'TikTok',
  Nextdoor = 'Nextdoor Agency',
  TikTokBusiness = 'TikTok Business',
  NextdoorUS = 'Nextdoor Agency US',
  Threads = 'Threads',
  Bluesky = 'Bluesky'
}

export function getSocialNetwork(accountType: AccountType): SocialNetwork {
  if (socialNetworkSettings[accountType]) {
    return socialNetworkSettings[accountType];
  } else {
    // check if an advertising account
    return Object.values(socialNetworkSettings).find(
      (network) =>
        !!network &&
        !!network.advertising &&
        network.advertising.accountTypeName === accountType
    );
  }
}

export enum AccountClass {
  Social = 'social', // could probably think of a better name here
  Advertising = 'advertising',
  Inbound = 'inbound'
  // can add LiveChat eventually as well
}

export class Account extends Record {
  id: string;
  name: string;
  is_reauth_required: boolean;
  account_type_id: string;
  is_reauth_advised: boolean;
  picture: string;
  social_id: string;
  username: string;
  uuid: string;
  account_authenticated_user_id: string;
  last_reported_reauth_at: string;
  socialNetworkUrl: string;
  alias: string;
  default_vanity_domain: string;
  default_vanity_domain_id: number | null;
  vanityDomain?: VanityDomain;
  socialNetwork: SocialNetwork;
  class: AccountClass;
  can_promote: string[];
  promoted_by: string[];
  ad_currency?: string; // 'GBP', 'USD', 'EUR'

  // setters must be present since deepMixIn utility is trying
  // to set the property (when deep copying the object) without checking if can set
  set createdBy(c: Colleague) {}
  get createdBy(): Colleague {
    if (!this.account_authenticated_user_id) {
      return undefined;
    }
    const colleaguesService = appInjector().get(ColleaguesService);
    return colleaguesService.store.find(this.account_authenticated_user_id);
  }

  private _account_type_name;

  set account_type_name(accountType: AccountType) {
    this.socialNetwork = getSocialNetwork(accountType);

    if (this.socialNetwork) {
      if (
        !!this.socialNetwork.advertising &&
        !!this.socialNetwork.advertising &&
        this.socialNetwork.advertising.accountTypeName === accountType
      ) {
        this.class = AccountClass.Advertising;
      } else {
        this.class = AccountClass.Social;
      }
    } else {
      this.class = AccountClass.Social;
    }

    this._account_type_name = accountType;
  }
  get account_type_name(): AccountType {
    return this._account_type_name;
  }

  get accountTypeLabel(): string {
    return this._account_type_name === AccountType.Twitter
      ? 'X'
      : this._account_type_name;
  }
  set accountTypeLabel(val) {}

  set accountTypeName(val) {}
  get accountTypeName(): string {
    const typeIdName: string = this._account_type_name
      .toLowerCase()
      .replace(/[- _]/g, '');
    return typeIdName;
  }

  set externalUrl(val) {}
  get externalUrl() {
    const socialNetwork = this.socialNetwork;
    return !!socialNetwork && !!socialNetwork.account // to maintain BC for tests only, in production this isnt needed
      ? socialNetwork.account.getExternalUrl(this)
      : '';
  }

  set displayName(val) {}
  get displayName() {
    return this.alias || this.name;
  }

  set accountTypeDisplayInfo(val) {}
  get accountTypeDisplayInfo(): KeyValueObject<string> {
    switch (this.account_type_name) {
      case AccountType.FacebookGroup:
        return {
          icon: 'fb-group',
          label: 'GROUP'
        };
        break;
      case AccountType.Facebook:
        return {
          icon: 'fb-page',
          label: 'PAGE'
        };
        break;
      default:
        return;
    }
  }

  markAsNeedingReauth() {
    return api
      .post(
        'account/index',
        { is_reauth_required: true },
        { params: { account_id: this.id, _method: 'PUT' } }
      )
      .then(() => {
        this.is_reauth_required = true;
        return this;
      });
  }

  update(changes) {
    return api
      .post('account/index', changes, {
        params: { account_id: this.id, _method: 'PUT' }
      })
      .then(() => {
        Object.assign(this, changes);
        return this;
      });
  }

  isFacebook(): boolean {
    return (
      this.account_type_name === AccountType.Facebook ||
      this.account_type_name === AccountType.FacebookGroup
    );
  }

  isLinkedin(): boolean {
    return this.account_type_name === AccountType.LinkedIn;
  }

  isYoutube(): boolean {
    return this.account_type_name === AccountType.YouTube;
  }

  isTwitter(): boolean {
    return this.account_type_name === AccountType.Twitter;
  }

  isInstagram(): boolean {
    return (
      this.account_type_name === AccountType.Instagram ||
      this.account_type_name === AccountType.InstagramBusiness
    );
  }

  isTikTok(): boolean {
    return this.account_type_name === AccountType.TikTok;
  }

  isMeta(): boolean {
    return (
      this.account_type_name === AccountType.Facebook ||
      this.account_type_name === AccountType.FacebookGroup ||
      this.account_type_name === AccountType.Instagram ||
      this.account_type_name === AccountType.InstagramBusiness ||
      this.account_type_name === AccountType.Threads
    );
  }

  isMetaWithoutThreads(): boolean {
    return (
      this.account_type_name === AccountType.Facebook ||
      this.account_type_name === AccountType.FacebookGroup ||
      this.account_type_name === AccountType.Instagram ||
      this.account_type_name === AccountType.InstagramBusiness
    );
  }

  isTikTokBusiness(): boolean {
    return this.account_type_name === AccountType.TikTokBusiness;
  }

  isNextdoor(): boolean {
    return this.account_type_name === AccountType.Nextdoor;
  }

  isNextdoorUS(): boolean {
    return this.account_type_name === AccountType.NextdoorUS;
  }

  isThreads(): boolean {
    return this.account_type_name === AccountType.Threads;
  }

  isBluesky(): boolean {
    return this.account_type_name === AccountType.Bluesky;
  }
}

export class AccountModel extends Model<Account> {
  constructor() {
    super('account', {
      endpoint: 'account/index',

      params: {
        with: [`livechat`, `nextdoor_agency`, `thread_accounts`, `bluesky`]
      },

      relations: {
        belongsTo: {
          // colleague: [
          //   {
          //     localKey: 'account_authenticated_user_id',
          //     localField: 'createdBy'
          //   }
          // ],
          vanityDomain: {
            localKey: 'default_vanity_domain_id',
            localField: 'vanityDomain'
          }
        }
      },

      recordClass: Account,

      deserialize(resourceConfig, attrs) {
        return Object.values(attrs.data);
      }
    });
  }

  findAccounts(
    workflowId?: number,
    {
      accountClass = AccountClass.Social,
      bypassPermissions = false
    }: { accountClass?: AccountClass; bypassPermissions?: boolean } = {}
  ): Promise<Account[]> {
    return utils.Promise.all([
      services.models.get<UserModel>('user').getAuthUser(),
      services.models.get<WorkflowModel>('accountGroup').loadAll()
    ]).then(([authUser]: any) => {
      let accounts: Account[];

      if (workflowId) {
        const workflow = services.models
          .get<WorkflowModel>('accountGroup')
          .get(workflowId);
        if (!workflow) {
          return [];
        }
        if (accountClass === AccountClass.Advertising) {
          accounts = workflow.accounts
            .map((account) =>
              account.promoted_by.map((accountId) => this.get(accountId))
            )
            .reduce(
              (flattenedAccounts, promotedAccounts) => [
                ...flattenedAccounts,
                ...promotedAccounts
              ],
              []
            );
        } else {
          accounts = workflow.accounts;
        }
      } else {
        accounts = this.getAll();
      }

      accounts = accounts.filter((account) => account.class === accountClass);

      return bypassPermissions
        ? accounts
        : accounts.filter((account) => authUser.hasAccountAccess(account));
    });
  }

  findAllAccounts(
    workflowId?: number,
    excludePermissions: boolean = false
  ): Promise<Account[]> {
    return utils.Promise.all([
      services.models.get<UserModel>('user').getAuthUser(),
      services.models.get<WorkflowModel>('accountGroup').loadAll()
    ]).then(([authUser]: any) => {
      let accounts: Account[];

      if (workflowId) {
        const workflow = services.models
          .get<WorkflowModel>('accountGroup')
          .get(workflowId);
        if (!workflow) {
          return [];
        }
        accounts = workflow.accounts;
      } else {
        accounts = this.getAll();
      }
      if (excludePermissions) {
        return accounts;
      } else {
        return accounts.filter((account) => authUser.hasAccountAccess(account));
      }
    });
  }

  getSocialIcon(accountType: string): string {
    return socialNetworkSettings[accountType].icon.web;
  }

  getAccountTypeFromId(accountId: string): string {
    const networks = Object.keys(socialNetworkSettings).map((network) => {
      return {
        key: network,
        typeID: socialNetworkSettings[network].accountTypeId
      };
    });
    return networks.find((network) => network.typeID === accountId).key;
  }
}

export function accountModelFactory(dataStore?) {
  return services.models.get('account') || new AccountModel();
}
