import './composer.component.scss';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ComposerComponent } from './composer.component';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import flatpickr from 'flatpickr';
import { FlatpickrModule } from 'angularx-flatpickr';
import {
  PUBLISHER_ACTIVE,
  PublisherActive
} from '../publisher/publisher-active';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmojiFormControlContainerModule } from '../../directives/emoji-form-control-container/emoji-form-control-container.module';
import { DropdownSelectModule } from '../dropdown-select/dropdown-select.module';
import { ScheduleFirstCommentModule } from '../schedule-first-comment/schedule-first-comment.module';
import { TooltipModule } from '../../directives/tooltip/tooltip.module';
import { EnlargeImageModule } from '../../directives/enlarge-image/enlarge-image.module';
import { UIResourcesModule } from '@ui-resources-angular';
import { AttachmentsComponent } from './attachments/attachments.component';
import { CalendarMonthModule } from 'angular-calendar/modules/month';
import { OffClickModule } from '../../directives/off-click/off-click.module';
import { HighchartModule } from '../highchart/highchart.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { ConfirmActionModule } from '../../directives/tooltip/confirm-action/confirm-action.module';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploaderModule } from '../../directives/file-uploader/file-uploader.module';
import { ElementLifecycleModule } from '../../directives/element-lifecycle/element-lifecycle.module';
import { InputUrlModule } from '../../directives/input-url/input-url.module';
import { FocusOnModule } from '../../directives/focus-on/focus-on.module';
import { NgUpgradeModule } from '../../ng-upgrade.module';
import { TextInputAutocompleteModule } from '../../components/text-input-autocomplete';
import { TextInputHighlightModule } from '../../components/text-input-highlight';
import { ContextMenuModule } from '../../components/context-menu/context-menu.module';
import { MenuModule } from '../menu/menu.module';
import { MediaNavModule } from '../media-nav/media-nav.module';
import { TruncateModule } from '../../pipes/truncate/truncate.module';
import { FilterByStringModule } from '../../pipes/filter-by-string/filter-by-string.module';
import { OutboxTypeaheadModule } from '../outbox-typeahead/outbox-typeahead.module';
import { ListSelectModule } from '../../../common/components/list-select/list-select.module';
import { OutboxPostListModule } from '../outbox-post-list/outbox-post-list.module';
import { SortByModule } from '../../pipes/sort-by/sort-by.module';
import { OrloComponentsModule } from 'orlo-components';
import { ToggleSwitch2Module } from '../toggle-switch-2/toggle-switch-2.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { IconModule } from '../icon/icon.module';
import { AccordionModule } from '../accordion/accordion.module';
import { DateTimePickerModule } from '../date-time-picker/date-time-picker.module';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { PostSchedulerModule } from '../post-scheduler/post-scheduler.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { SchedulePostsComponent } from './schedule-posts/schedule-posts.component';
import { TargetingOptionsComponent } from './targeting-options/targeting-options.component';
import { DayModule } from '../../pipes/day/day.module';
import { AccountSelectorModule } from './account-selector/account-selector.module';
import { ShareFirstCommentModule } from '../share-first-comment/share-first-comment.module';
import { ComposeAreaModule } from './compose-area/compose-area.module';
import { TargetingOptionsModule } from './targeting-options/targeting-options.module';
import { PostErrorsModule } from './post-errors/post-errors.module';
import { TagsSelectCreateModule } from '../tags-select-create/tags-select-create.module';
import { ListSelect2Module } from '../list-select2/list-select2.module';
import { DropdownSelect2Module } from '../dropdown-select-2/dropdown-select-2.module';
import { CampaignsSelectCreateComponent } from './campaigns-select-create/campaigns-select-create.component';
import { CreateCampaignModalComponent } from './campaigns-select-create/create-campaign-modal/create-campaign-modal.component';

export function publisherActiveFactory(): PublisherActive {
  return new Subject();
}

export function flatpickrFactory() {
  return flatpickr;
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UIRouterModule,
    EmojiFormControlContainerModule,
    DropdownSelectModule,
    ListSelectModule,
    TooltipModule,
    EnlargeImageModule,
    UIResourcesModule,
    TextInputHighlightModule,
    TextInputAutocompleteModule,
    MenuModule,
    ContextMenuModule,
    OffClickModule,
    CalendarMonthModule,
    FlatpickrModule.forRoot(),
    HighchartModule,
    ProgressBarModule,
    ConfirmActionModule,
    NgbTimepickerModule.forRoot(),
    FileUploaderModule,
    ElementLifecycleModule,
    InputUrlModule,
    FocusOnModule,
    MediaNavModule,
    TruncateModule,
    FilterByStringModule,
    NgUpgradeModule, // so that the upgrade outbox-post-list component works
    OutboxTypeaheadModule,
    OutboxPostListModule,
    SortByModule,
    ScheduleFirstCommentModule,
    OrloComponentsModule,
    CheckboxModule,
    ToggleSwitch2Module,
    IconModule,
    AccordionModule,
    ToggleSwitch2Module,
    DateTimePickerModule,
    TimePickerModule,
    OverlayModule,
    PostSchedulerModule,
    DayModule,
    AccountSelectorModule,
    PostErrorsModule,
    ShareFirstCommentModule,
    ComposeAreaModule,
    TargetingOptionsModule,
    TagsSelectCreateModule,
    DropdownSelect2Module,
    ListSelect2Module
  ],
  declarations: [
    ComposerComponent,
    SchedulePostsComponent,
    AttachmentsComponent,
    CampaignsSelectCreateComponent,
    CreateCampaignModalComponent
  ],
  providers: [
    { provide: PUBLISHER_ACTIVE, useFactory: publisherActiveFactory },
    DatePipe
  ],
  entryComponents: [CreateCampaignModalComponent]
})
export class ComposerModule {}
