import './campaigns-select-create.component.scss';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { Account, OutboxPublisher, User } from '@ui-resources-angular';
import { Campaign, CampaignsService } from '../../../services/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCampaignModalComponent } from './create-campaign-modal/create-campaign-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { orderBy } from 'lodash-es';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ssi-campaigns-select-create',
  templateUrl: './campaigns-select-create.component.html'
})
export class CampaignsSelectCreateComponent implements OnInit, OnDestroy {
  @Input() authUser: User;
  @Input() post: OutboxPublisher;

  campaigns: Array<Campaign | any> = [];
  filteredCampaigns: Array<Campaign | any> = [];

  canManageCampaigns: boolean;
  destroyed$ = new Subject();

  @Output() onCampaignSelected = new EventEmitter<Campaign>();

  constructor(
    protected campaignsService: CampaignsService,
    protected modal: NgbModal,
    protected datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.subscribeToCampaignsList();
    this.canManageCampaigns = this.authUser.hasCompanyPermission(
      'manage_campaigns'
    );
  }

  selectCampaignChange(campaign: Campaign, parentCampaign?: Campaign) {
    this.onCampaignSelected.emit(campaign);
  }

  deselectCampaign() {
    this.onCampaignSelected.emit(null);
  }

  async openNewCampaignModal() {
    const modal = this.modal.open(CreateCampaignModalComponent, {
      centered: true
    });
    modal.componentInstance.campaigns = this.campaigns;
    const newCampaignForm = await modal.result;
    console.log('newCampaignForm:', newCampaignForm);
    const newCampaign = new Campaign(newCampaignForm);
    this.campaignsService.createOrUpdate(newCampaign);
  }

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }

  async subscribeToCampaignsList() {
    this.campaignsService.getAll();
    this.campaignsService.store.value$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((campaigns) => {
        const campaignsSortedByParent = this.campaignsService.sortByParent(
          campaigns
        );
        this.campaigns = campaignsSortedByParent.live;

        this.campaigns = this.campaigns
          .filter((c) => !c.parent_id)
          .map((p) => {
            const children = this.campaigns.filter((c) => c.parent_id === p.id);
            return {
              ...p,
              _children: children.length
                ? {
                    expanded: false,
                    options: children,
                    filteredOptions: [...children]
                  }
                : undefined
            };
          });

        this.filteredCampaigns = [...this.campaigns];
      });
  }

  campaignsFilterFn = (filterTerm: string) => {
    const skipFiltering = !filterTerm || filterTerm.length < 2;
    if (skipFiltering) {
      this.filteredCampaigns = [...this.campaigns];
      this.campaigns.forEach((parent: Campaign | any) => {
        if (parent._children) {
          parent._children.filteredOptions = [...parent._children.options];
          if (
            filterTerm.length === 1 &&
            !parent._children.options.some((c) => c === this.post.campaign)
          ) {
            parent._children.expanded = false;
          }
        }
      });
      return this.filteredCampaigns;
    }

    this.filteredCampaigns = this.campaigns.filter((parent: Campaign | any) => {
      const parentMatch = (parent.name || '')
        .toLowerCase()
        .includes(filterTerm.toLowerCase());

      let childrenMatch = false;
      if (parent._children) {
        parent._children.filteredOptions = parent._children.options.filter(
          (child) => {
            return (child.name || '')
              .toLowerCase()
              .includes(filterTerm.toLowerCase());
          }
        );

        childrenMatch = !!parent._children.filteredOptions.length;
        parent._children.expanded = !!childrenMatch;
      }

      return parentMatch || childrenMatch;
    });

    return this.filteredCampaigns;
  };

  getTooltipContent = (campaign: Campaign): string => {
    if (!campaign.started_at && !campaign.closed_at) {
      return '';
    }
    return (
      'This campaign runs ' +
      (campaign.started_at
        ? 'from ' + this.datePipe.transform(campaign.started_at, 'MMM d, y')
        : '') +
      (campaign.closed_at
        ? ' until ' + this.datePipe.transform(campaign.closed_at, 'MMM d, y')
        : '')
    );
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
