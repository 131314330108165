import { Injectable } from '@angular/core';
import { COUNTRIES } from '../../../constants';
import { Suggestion } from '../../../../../../library/interfaces/suggestion';
import * as linkedinTargeting from './linkedin-options/linkedin-targeting';
import { AccountTypeName } from '../../../enums';

const ALL_COUNTRIES = Object.keys(COUNTRIES).map((countryCode) => {
  return {
    code: countryCode,
    label: COUNTRIES[countryCode]
  };
});

export interface MultiValueInput {
  disable?: boolean;
  key: string;
  labelTranslateKey: string;
  options?: Array<{ code: string; label: string }>;
  placeholderTranslateKey: string;
  value: any[];
}

interface SingleValueInputs {
  gender: any;
  relationship_status: any;
  min_age: any;
  max_age: any;
}

interface TargetingOptions {
  Facebook?: {
    multiValueInputs?: MultiValueInput[];
    singleValueInputs?: SingleValueInputs;
  };
  LinkedIn?: {
    multiValueInputs?: MultiValueInput[];
  };
  [AccountTypeName.Nextdoor]?: {
    selectedBoundaries?: any[];
  };
  [AccountTypeName.NextdoorUS]?: {
    selectedBoundaries?: any[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class TargetingOptionsService {
  options: TargetingOptions = {
    Facebook: {},
    LinkedIn: {},
    [AccountTypeName.Nextdoor]: {},
    [AccountTypeName.NextdoorUS]: {}
  };

  constructor() {}

  initialiseTargeting(accountTypeName?: AccountTypeName, value?: any) {
    console.log(
      `Initialising ${accountTypeName} targeting with previous:`,
      value
    );
    switch (accountTypeName) {
      case AccountTypeName.Facebook:
        this.options.Facebook = {
          multiValueInputs: [
            {
              key: 'countries',
              labelTranslateKey: 'COUNTRIES',
              options: ALL_COUNTRIES,
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_COUNTIES',
              value:
                value && value.countries
                  ? value.countries
                  : ([] as Suggestion[])
            },
            {
              key: 'cities',
              labelTranslateKey: 'CITIES',
              options: [],
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_CITIES',
              value: value && value.cities ? value.cities : []
            }
          ],
          singleValueInputs: {
            gender: value && value.gender ? value.gender : null,
            relationship_status: value.relationship_status
              ? value.relationship_status
              : null,
            min_age: value && value.min_age ? value.min_age : null,
            max_age: value && value.max_age ? value.max_age : null
          }
        };
        break;
      case AccountTypeName.LinkedIn:
        this.options.LinkedIn = {
          multiValueInputs: [
            {
              key: 'geoLocations',
              labelTranslateKey: 'LOCATIONS',
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_LOCATIONS',
              options: [],
              value: value && value.geoLocations ? value.geoLocations : []
            },
            {
              key: 'companySizes',
              labelTranslateKey: 'COMPANY_SIZE',
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_COMPANY_SIZES',
              options: linkedinTargeting.companySizes,
              value: value && value.companySizes ? value.companySizes : []
            },
            {
              key: 'jobFunc',
              labelTranslateKey: 'JOB_FUNCTION',
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_JOB_FUNCTION',
              options: linkedinTargeting.jobFunc,
              value: value && value.jobFunc ? value.jobFunc : []
            },
            {
              key: 'industries',
              labelTranslateKey: 'INDUSTRY',
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_INDUSTRIES',
              options: linkedinTargeting.industries,
              value: value && value.industries ? value.industries : []
            },
            {
              key: 'seniorities',
              labelTranslateKey: 'SENIORITY_LEVEL',
              placeholderTranslateKey: 'TYPE_TO_SEARCH_FOR_SENIORITIES',
              options: linkedinTargeting.seniorities,
              value: value && value.seniorities ? value.seniorities : []
            }
          ]
        };
        break;

      case AccountTypeName.Nextdoor:
        this.options[AccountTypeName.Nextdoor] = {
          selectedBoundaries:
            value && value.selectedBoundaries ? value.selectedBoundaries : []
        };

        break;
      case AccountTypeName.NextdoorUS:
        this.options[AccountTypeName.NextdoorUS] = {
          selectedBoundaries:
            value && value.selectedBoundaries ? value.selectedBoundaries : []
        };

        break;
      default:
        break;
    }
  }
}
