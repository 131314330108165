import './outbox-campaign-modal.component.scss';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { orderBy } from 'lodash-es';

import { API, Outbox } from '@ui-resources-angular';
import { CampaignsService, Campaign } from '../../../common/services/api';
@Component({
  selector: 'ssi-outbox-campaign-modal',
  templateUrl: './outbox-campaign-modal.component.html',
  styles: []
})
export class OutboxCampaignModalComponent implements OnInit {
  @Input() post: Outbox;

  parents: Campaign[] = [];
  filteredParents: Campaign[] = [];
  selectedCampaign: Campaign;

  constructor(
    private api: API,
    public modalInstance: NgbActiveModal,
    private campaignsService: CampaignsService
  ) {}

  async ngOnInit() {
    let campaigns = await this.campaignsService.getAll();

    this.selectedCampaign =
      this.post.campaign_id &&
      campaigns.find((c) => c.id === this.post.campaign_id);

    campaigns = campaigns.filter((c) => !c.is_closed && !c.is_deleted);
    campaigns = orderBy(campaigns, [(c) => c.name.toLowerCase()]);

    this.parents = campaigns.filter((c) => !c.parent_id);

    this.parents.forEach((parent) => {
      const children = campaigns.filter((c) => c.parent_id === parent.id);
      if (children.length) {
        (parent as any)._children = {
          expanded: false,
          options: children,
          filteredOptions: [...children]
        };

        if (children.some((c) => c === this.selectedCampaign)) {
          (parent as any)._children.expanded = true;
        }
      }
    });

    this.filteredParents = [...this.parents];
  }

  changeCampaign() {
    const newCampaignId = this.selectedCampaign
      ? this.selectedCampaign.id
      : null;

    const params = {
      outbox_id: this.post.id,
      new_campaign_id: newCampaignId
    };

    this.api
      .post('campaign/updateOutbox', Object.assign({}, params))
      .then(() => (this.post.campaign = this.selectedCampaign))
      .then(() => this.modalInstance.close(newCampaignId));
  }

  onModelValueChange(parent: Campaign | any): void {
    if (parent && parent._children) {
      parent._children.expanded = true;
    }
  }

  onChildModelValueChange(child: Campaign, parent: Campaign): void {}

  expandCollapseCampaign(parent: Campaign | any): void {
    parent._children.expanded = !parent._children.expanded;
  }

  campaignsFilterFn = (filterTerm: string) => {
    const skipFiltering = !filterTerm || filterTerm.length < 2;
    if (skipFiltering) {
      this.filteredParents = [...this.parents];
      this.parents.forEach((parent: Campaign | any) => {
        if (parent._children) {
          parent._children.filteredOptions = [...parent._children.options];
          if (
            filterTerm.length === 1 &&
            !parent._children.options.some((c) => c === this.selectedCampaign)
          ) {
            parent._children.expanded = false;
          }
        }
      });
      return this.filteredParents;
    }

    this.filteredParents = this.parents.filter((parent: Campaign | any) => {
      const parentMatch = (parent.name || '')
        .toLowerCase()
        .includes(filterTerm.toLowerCase());

      let childrenMatch = false;
      if (parent._children) {
        parent._children.filteredOptions = parent._children.options.filter(
          (child) => {
            return (child.name || '')
              .toLowerCase()
              .includes(filterTerm.toLowerCase());
          }
        );

        childrenMatch = !!parent._children.filteredOptions.length;
        parent._children.expanded = !!childrenMatch;
      }

      return parentMatch || childrenMatch;
    });

    return this.filteredParents;
  };
}
