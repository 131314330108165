<div class="composer-attachments">
  <h3>Attachments</h3>
  <div class="post-attachments-container">

    <div class="post-attachment"
         *ngFor="let file of composer.files"
         (mouseenter)="file.hovered = true;"
         (mouseleave)="file.hovered = false;"
         [class.hovered]="file.hovered">

      <img [src]="file.url"
           ssiEnlargeImage
           *ngIf="file.type === OutboxFileType.Image || file.type === OutboxFileType.Gif" />

      <video controls
             *ngIf="file.type === OutboxFileType.Video">
        <source [src]="file.url"
                type="video/mp4">
        Cannot preview video
      </video>

      <div class="post-attachment-type">
        <i [class]="iconType(file)"></i>
      </div>

      <ssi-menu #attachOptionsMenu
                *ngIf="!disable?.editMedia"
                [headAlignment]="'top right'"
                [bodyAlignment]="'top right'"
                class="post-attachment-options-menu">
        <ng-template ssiTemplateSelector="menuHead">
          <div class="post-attachment-options-menu-trigger">
            <i class="ssi ssi-outbox-menu"></i>
          </div>
        </ng-template>
        <ng-template ssiTemplateSelector="menuBody">
          <ul class="g-list post-attachment-options-list">
            <li class="g-list-item"
                *ngIf="file.type === OutboxFileType.Image"
                (click)="editImage(file)">
              <i class="ssi ssi-correct-edit-small"></i>
              <span>Edit image</span>
            </li>
            <li class="g-list-item"
                *ngIf="canAddAltText(file)"
                [ssiTooltip]="'ALT_TEXT_DESCRIPTION_NETWORK_SPECIFIC' | translate"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'dark rounded'"
                [tooltipIconClass]="'ssi ssi-pro-tip'"
                (click)="addOrEditAltText(file)">
              <i class="ssi ssi-alt-text-small"></i>
              <span>{{file.alt_text ? 'Edit Alt Text' : 'Add Alt text' }}</span>
            </li>
            <li class="g-list-item"
                *ngIf="canAddCaptions(file)"
                [ssiTooltip]="'Adding captions ensure your videos are accesible for users with hearing problems'"
                [tooltipAppendToBody]="true"
                [tooltipWindowClass]="'tooltip-chunky'"
                (click)="addOrEditCaptions(file)">
              <i class="ssi ssi-alt-text-small"></i>
              <span>{{file.subtitles ? 'Change captions' : 'Add captions' }}</span>
            </li>
            <li class="g-list-item"
                (click)="remove(file)">
              <i class="ssi ssi-small-delete remove-icon"></i>
              <span>Remove</span>
            </li>
          </ul>
        </ng-template>
      </ssi-menu>

      <!-- <i class="ssi ssi-correct-play play-button"
      *ngIf="file.type === OutboxFileType.Video"></i> -->

      <ng-container *ngIf="!disable?.editMedia">
        <div class="post-attachment-action top-left action-remove"
             *ngIf="file.hovered"
             (click)="remove(file)">
          <i class="ssi ssi-small-delete"></i>
        </div>

        <div class="post-attachment-action bottom-left action-edit"
             *ngIf="file.alt_text"
             [ssiTooltip]="file.alt_text"
             [tooltipAppendToBody]="true"
             [tooltipWindowClass]="'tooltip-chunky'"
             (click)="addOrEditAltText(file)">
          <i class="ssi ssi-alt-text-small"></i>
        </div>

        <div class="post-attachment-action bottom-left action-edit"
             *ngIf="file.subtitles"
             [ssiTooltip]="subtitleFilename"
             [tooltipAppendToBody]="true"
             [tooltipWindowClass]="'tooltip-chunky'"
             (click)="addOrEditCaptions(file)">
          <i class="ssi ssi-captions"></i>
        </div>
      </ng-container>

    </div>

    <div class="post-attachment upload-progress"
         *ngFor="let file of composer.uploadingFiles">

      <div class="post-attachment-cancel"
           *ngIf="noneUploaded()"
           (click)="cancelUpload()">
        <i class="ssi ssi-delete-close"></i>
      </div>

      <div class="post-attachment-upload-progress-type">
        <i [class]="iconType(file)"></i>
      </div>

      <div class="post-attachment-name">
        <span>{{file.filename}}</span>
      </div>

      <div class="post-attachment-size">
        <span>{{bytesToMb(file.size)}} mb</span>
      </div>

      <div class="post-attachment-progress-bar"
           *ngIf="!file.uploaded">
        <ssi-progress-bar [max]="100"
                          [progress]="file.uploadProgress.totalPercent"
                          [thickness]="8"
                          bgColor="#F0F3F9"
                          [color]="'#14BAE3'"
                          [radius]="5"></ssi-progress-bar>
      </div>

      <div class="post-attachment-progress-text"
           *ngIf="!file.uploaded">
        <span>{{file.uploadProgress.totalPercent}}%</span>
      </div>

      <div class="post-attachment-progress-spin"
           *ngIf="file.uploaded">
        <i class="ssi ssi-2x ssi-loading ssi-spin spinner"></i>
        <span *ngIf="videoResizingInProgress && file.type === OutboxFileType.Video"
              class="resizing-video-text"
              translate="WE_ARE_RESIZING_YOUR_VIDEO"></span>
        <!-- <span>Validating file...</span> -->
      </div>
    </div>

    <div class="post-attachment add-new"
         *ngIf="composer.accounts.length && (composer.mediaRestrictions.image.remaining > 0 || composer.mediaRestrictions.video.remaining > 0)"
         [hidden]="composer.filesUploadingOrValidating"
         [class.hidden]="composer.filesUploadingOrValidating">
      <!-- <div class="post-attachment loading-cover"
           *ngIf="videoResizingInProgress">
        <i class="ssi ssi-3x ssi-loading ssi-spin"></i>
        <span translate="WE_ARE_RESIZING_YOUR_VIDEO"></span>
      </div> -->

      <button class="g-btn round-icon-only-button"
              (click)="mediaUploadOptionsMenu.hide(); mediaUploadOptionsMenu.show($event, {});">
        <i class="ssi ssi-add-small"></i>
      </button>

      <ssi-context-menu #mediaUploadOptionsMenu
                        [offsetTop]="-40"
                        [offsetLeft]="-60"
                        [attachToBody]="true"
                        [onlyHideMenuBody]="true">
        <!-- attachToBody is fix for weird issue with 'position: fixed' not working properly if animation is used on the parent component, see https://stackoverflow.com/questions/16879737/position-fixed-is-not-working -->
        <ng-template let-ctx>
          <ng-container *ngTemplateOutlet="mediaUploadOptionsMenuBodyTemplate;"></ng-container>
        </ng-template>
      </ssi-context-menu>
    </div>
  </div>
</div>