import './linkedin-options.component.scss';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountTypeId, AccountTypeName} from '../../../../enums';
import {OutboxPublisher, socialNetworkSettings} from '@ui-resources-angular';
import {MultiValueInput, TargetingOptionsService} from '../targeting-options.service';
import {Suggestion} from "../../../../../../../library/interfaces/suggestion";

@Component({
  selector: 'ssi-linkedin-options',
  templateUrl: './linkedin-options.component.html'
})
export class LinkedInOptionsComponent implements OnInit {
  @Input() post: OutboxPublisher;
  @Input() previewToggled: boolean;

  @Output() select = new EventEmitter();

  constructor(public targetingService: TargetingOptionsService) {}

  getLinkedInTargetingInput(key: string): MultiValueInput {
    return this.targetingService.options.LinkedIn.multiValueInputs.find(
      (input) => input.key === key
    );
  }

  ngOnInit() {
    console.log('Should initialise LinkedIn targeting...');
    this.targetingService.initialiseTargeting(
      AccountTypeName.LinkedIn,
      this.post.targeting.LinkedIn
    );
    this.buildFromPost();
  }

  onChange(value) {
    this.select.emit(value);
  }

  async buildFromPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      input.value = (this.post.targeting.LinkedIn[input.key] || []).map(
        (value) => {
          if (input.key === 'cities' || input.key === 'interests') {
            return value;
          }

          if (!(!!input && !!input.options && Array.isArray(input.options))) {
            return;
          }

          return input.options.find((option) =>
            typeof option.code === 'string'
              ? option.code.toLocaleLowerCase() === value.toLocaleLowerCase()
              : option.code === value
          );
        }
      );
    });
  }

  async getGeoLocationsForQuery(text: string): Promise<Suggestion[]> {
    if (!text) {
      return [];
    }

    const li = socialNetworkSettings.LinkedIn;

    if (
      !(
        !!this.post &&
        !!this.post.accounts &&
        Array.isArray(this.post.accounts)
      )
    ) {
      return;
    }

    const account = this.post.accounts.find(
      (iAccount) => iAccount.socialNetwork === li
    );

    return await li.publish.targeting.geoLocations(text, account);
  }

  async onLinkedInGeosChange(filter: string) {
    if (filter.length > 2) {
      const foundGeos = await this.getGeoLocationsForQuery(filter);
      const geoTargeting = this.getLinkedInTargetingInput('geoLocations');
      geoTargeting.options = foundGeos;
    }
  }

  async copyToPost() {
    this.targetingService.options.LinkedIn.multiValueInputs.forEach((input) => {
      if (input.key === 'cities' || input.key === 'interests') {
        this.post.targeting.LinkedIn[input.key] = input.value;
      } else {
        this.post.targeting.LinkedIn[input.key] = input.value.map(
          (value) => value.code
        );
      }
    });

  }

  public readonly AccountTypeId = AccountTypeId;
}
