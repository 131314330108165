import { utils, Record } from 'js-data';
import { api } from '../../core/services/api';
import { Account, AccountModel } from './accountModel';
import { services } from '../../common';
import { Model } from '../../model';
import { VanityDomainModel } from './vanityDomainModel';
import { appInjector } from '../../../../../apps/angular/app-injector';
import { ColleaguesService } from '../../../../../apps/angular/common/services/api';

export class Workflow extends Record {
  id: number;
  name: string;
  accounts: Account[];

  addAccount(account) {
    this.accounts.push(account);
    return api.post('accountGroup/account', {
      account_group_id: this.id,
      account_id: account.id
    });
  }

  removeAccount(account) {
    const index = this.accounts.indexOf(account);
    this.accounts.splice(index, 1);
    return api.del('accountGroup/account', {
      params: { account_group_id: this.id, account_id: account.id }
    });
  }

  toggleAccount(account) {
    const index = this.accounts.indexOf(account);
    if (index > -1) {
      return this.removeAccount(account);
    } else {
      return this.addAccount(account);
    }
  }
}

export class WorkflowModel extends Model<Workflow> {
  constructor() {
    super('accountGroup', {
      endpoint: 'accountGroup/index',
      deserialize(resourceConfig, attrs) {
        return Object.values(attrs.data);
      },
      recordClass: Workflow
    });
  }

  loadAll(forceReload = false) {
    const options: any = {};
    if (forceReload) {
      options.bypassCache = true;
    }
    const accountModel = services.models.get<AccountModel>('account');
    const vanityDomainModel = services.models.get<VanityDomainModel>(
      'vanityDomain'
    );
    const colleaguesService = appInjector().get(ColleaguesService);

    // hack to ensure relations work properly
    return utils.Promise.all([
      vanityDomainModel.findAll(),
      colleaguesService.getAll()
    ])
      .then(() => {
        return utils.Promise.all([
          this.findAll({}, options),
          accountModel.findAll(
            {},
            {
              ...options,
              params: {
                with: [
                  'ad_accounts',
                  'livechat',
                  'tiktok',
                  'reviewtrackers',
                  'nextdoor_agency',
                  'thread_accounts',
                  'bluesky'
                ]
              }
            }
          )
        ]);
      })
      .then(([workflows]) => {
        return workflows.map((workflow) => {
          workflow.accounts = workflow.accounts
            .map((account) => accountModel.get(account.id)) // use the account that was retrieved in the other http request
            .filter((account) => !!account); // check the account hasnt been deleted
          return workflow;
        });
      });
  }

  create(name: string): Promise<Workflow> {
    return api
      .post('accountGroup/index', { name })
      .then((result) => this.inject(result.data));
  }

  destroy(workflow: Workflow) {
    return api
      .del('accountGroup/index', { params: { account_group_id: workflow.id } })
      .then((result) => {
        this.eject(workflow);
        return result.data;
      });
  }
}

export function workflowModelFactory(dataStore?) {
  return services.models.get('workflow') || new WorkflowModel();
}
